var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasProductsData
    ? _c(
        "div",
        { staticClass: "special-offer special-offer--widget product-list" },
        [
          _c("p", { staticClass: "special-offer__title" }, [
            _vm._v(_vm._s(_vm.title))
          ]),
          _vm._v(" "),
          _vm._l(_vm.productsData.items, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                attrs: {
                  "data-gtm-product-id": _vm.gaListValue + "-" + item.sku,
                  "gtm-element": "product-item"
                }
              },
              [
                _c("div", { staticClass: "special-offer__box item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "special-offer__img",
                      attrs: { href: item.url }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: item.img,
                          alt: item.name,
                          width: "265",
                          height: "265"
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "special-offer__content" }, [
                    _c("p", { staticClass: "special-offer__header" }, [
                      _vm._v(_vm._s(item.name))
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "special-offer__price" }, [
                      _vm._v(
                        _vm._s(_vm.formatPrice(item.price)) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "special-offer__cta" },
                      [
                        _c("ProductForm", {
                          attrs: {
                            "form-data": item,
                            "ga-list-value": _vm.gaListValue,
                            formkey: _vm.formkeyValue,
                            "widget-type": _vm.widgetType,
                            "widget-title": _vm.title
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ]
            )
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }