var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        class: "product_addtocart_form_" + _vm.formData.id,
        attrs: { action: _vm.addToCartUrl, enctype: "multipart/form-data" }
      },
      [
        _c("input", {
          attrs: { name: "form_key", type: "hidden" },
          domProps: { value: _vm.formkey }
        }),
        _vm._v(" "),
        _c("input", {
          attrs: { id: "product_id", type: "hidden", name: "product" },
          domProps: { value: _vm.formData.id }
        }),
        _vm._v(" "),
        _c("input", {
          attrs: { id: "listCategory", type: "hidden", name: "listCategory" },
          domProps: { value: _vm.gaListValue + "-" + _vm.widgetTitle }
        }),
        _vm._v(" "),
        _vm.widgetType === "offer"
          ? _c(
              "button",
              {
                staticClass: "special-offer__btn btn--red",
                class: "product_addtocart_button_" + _vm.formData.id,
                attrs: { type: "button" },
                on: { click: _vm.addToCart }
              },
              [_vm._v("\n            Dodaj do koszyka\n        ")]
            )
          : _c(
              "button",
              {
                staticClass: "product-item__btn",
                class: "product_addtocart_button_" + _vm.formData.id,
                attrs: { type: "button" },
                on: { click: _vm.addToCart }
              },
              [
                _c("span", { staticClass: "label" }, [
                  _c("span", { staticClass: "price" }, [
                    _vm._v(_vm._s(_vm.formattedPrice))
                  ])
                ]),
                _vm._v(" "),
                _c("CartIcon", {
                  staticClass: "icon icon-cart icon--inline",
                  attrs: { width: "30", height: "30" }
                })
              ],
              1
            )
      ]
    ),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tooltip-max-price-info" }, [
      _c("p", [
        _vm._v("\n            Podana cena jest ceną maksymalną.\n            "),
        _c("span", { attrs: { "data-tooltip-cms": "product_shipment_info" } }, [
          _c("span", [_vm._v("Dowiedz się więcej")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }