<template>
    <div
        v-if="hasProductsData"
        class="special-offer special-offer--widget product-list"
    >
        <p class="special-offer__title">{{ title }}</p>
        <div
            v-for="(item, index) in productsData.items"
            :key="index"
            :data-gtm-product-id="`${gaListValue}-${item.sku}`"
            gtm-element="product-item"
        >
            <div class="special-offer__box item">
                <a :href="item.url" class="special-offer__img">
                    <img
                        :src="item.img"
                        :alt="item.name"
                        width="265"
                        height="265"
                    />
                </a>
                <div class="special-offer__content">
                    <p class="special-offer__header">{{ item.name }}</p>
                    <span class="special-offer__price"
                        >{{ formatPrice(item.price) }}
                    </span>

                    <div class="special-offer__cta">
                        <ProductForm
                            :form-data="item"
                            :ga-list-value="gaListValue"
                            :formkey="formkeyValue"
                            :widget-type="widgetType"
                            :widget-title="title"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProductForm from './../ProductForm/ProductForm.vue'
import ProductsList from './..//ProductsList'
export default {
    name: 'SpecialOffer',
    components: {
        ProductForm
    },
    extends: ProductsList,
    inheritAttrs: false,
    props: {
        widgetType: {
            type: String,
            default: 'offer'
        },
        gaListValue: {
            type: String,
            default: 'cms-specialOffer'
        }
    },
    data() {
        return {
            formkeyValue: ''
        }
    },
    watch: {
        productsData(hasContent) {
            if (hasContent) {
                this.$nextTick(() => {
                    window.Aoe_Static.replaceAjaxBlocks()
                })
            }
        }
    }
}
</script>
