var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasProductsData
    ? _c("div", { staticClass: "content-product-list" }, [
        _c(
          "h3",
          {
            ref: "header",
            staticClass: "content-product-list__title",
            attrs: { "data-widget": _vm.widgetId },
            on: { click: _vm.addTabClass }
          },
          [_vm._v("\n        " + _vm._s(_vm.title) + "\n    ")]
        ),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "content-product-list__items" },
          _vm._l(_vm.productsData.items, function(item, index) {
            return _c(
              "li",
              {
                key: index,
                attrs: {
                  "data-gtm-product-id": _vm.gaListValue + "-" + item.sku,
                  "gtm-element": "product-item"
                }
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "content-product-list__link",
                    attrs: { href: item.url }
                  },
                  [
                    _c("div", { staticClass: "content-product-list__img" }, [
                      _c("img", {
                        attrs: {
                          src: item.img,
                          alt: item.name,
                          width: "100",
                          height: "100"
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content-product-list__data" }, [
                      _c("p", { staticClass: "content-product-list__name" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(item.name) +
                            "\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "content-product-list__price" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.formatPrice(item.price)) +
                            "\n                    "
                        )
                      ])
                    ])
                  ]
                )
              ]
            )
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }