<template>
    <div>
        <form
            :action="addToCartUrl"
            :class="`product_addtocart_form_${formData.id}`"
            enctype="multipart/form-data"
        >
            <input name="form_key" type="hidden" :value="formkey" />
            <input
                id="product_id"
                type="hidden"
                name="product"
                :value="formData.id"
            />
            <input
                id="listCategory"
                type="hidden"
                name="listCategory"
                :value="`${gaListValue}-${widgetTitle}`"
            />
            <button
                v-if="widgetType === 'offer'"
                type="button"
                class="special-offer__btn btn--red"
                :class="`product_addtocart_button_${formData.id}`"
                @click="addToCart"
            >
                Dodaj do koszyka
            </button>
            <button
                v-else
                type="button"
                class="product-item__btn"
                :class="`product_addtocart_button_${formData.id}`"
                @click="addToCart"
            >
                <span class="label">
                    <span class="price">{{ formattedPrice }}</span>
                </span>
                <CartIcon
                    class="icon icon-cart icon--inline"
                    width="30"
                    height="30"
                />
            </button>
        </form>
        <div class="tooltip-max-price-info">
            <p>
                Podana cena jest ceną maksymalną.
                <span data-tooltip-cms="product_shipment_info">
                    <span>Dowiedz się więcej</span>
                </span>
            </p>
        </div>
    </div>
</template>
<script>
import CartIcon from '@icons/cart2.svg'
import { formatPrice } from '@js/utils/utils'

export default {
    name: 'ProductForm',
    components: {
        CartIcon
    },
    props: {
        formData: {
            type: Object,
            required: true
        },
        formkey: {
            type: String,
            required: true
        },
        widgetType: {
            type: String,
            required: true
        },
        gaListValue: {
            type: String,
            required: true
        },
        widgetTitle: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            tooltipTitle: ' ',
            originalTooltip: null
        }
    },
    computed: {
        addToCartUrl() {
            return '/ajaxcart/index/add'
        },
        formattedPrice() {
            return formatPrice(this.formData.price)
        }
    },
    methods: {
        addToCart(event) {
            if (typeof $apteline !== 'undefined' && event.currentTarget) {
                window.$apteline.cart.AddToCart(event.currentTarget)
            }
        }
    }
}
</script>
