var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasProductsData
    ? _c("div", { staticClass: "cms-product-slider" }, [
        _c("h2", { staticClass: "cms-product-slider__title" }, [
          _vm._v(_vm._s(_vm.title))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cms-product-slider__items products-list" }, [
          _c(
            "div",
            { ref: "sliderItems", staticClass: "cms-product-slider__wrapper" },
            _vm._l(_vm.productsData.items, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  attrs: {
                    "data-gtm-product-id": _vm.gaListValue + "-" + item.sku,
                    "gtm-element": "product-item"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "cms-product-slider__item product-item" },
                    [
                      _c(
                        "a",
                        {
                          staticClass:
                            "cms-product-slider__img product-item__img",
                          attrs: { href: item.url }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: item.img,
                              alt: item.name,
                              width: "110",
                              height: "110"
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "cms-product-slider__content" },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "cms-product-slider__header product-item__name",
                              attrs: { href: item.url }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(item.name) +
                                  "\n                        "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "cms-product-slider__cart" },
                            [
                              _c("ProductForm", {
                                attrs: {
                                  "form-data": item,
                                  "ga-list-value": _vm.gaListValue,
                                  formkey: _vm.formkeyValue,
                                  "widget-type": _vm.widgetType,
                                  "widget-title": _vm.title
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.productsData &&
                    _vm.productsData.items &&
                    Object.keys(_vm.productsData.items).length > 3,
                  expression:
                    "\n                productsData &&\n                productsData.items &&\n                Object.keys(productsData.items).length > 3\n            "
                }
              ],
              ref: "arrows",
              staticClass: "cms-product-slider__switcher"
            },
            [
              _c(
                "button",
                {
                  ref: "arrowLeft",
                  staticClass:
                    "btn btn--clear cms-product-slider__arrow cms-product-slider__arrow--left",
                  attrs: {
                    "aria-label": "Poprzedni produkt",
                    title: "Poprzedni produkt"
                  }
                },
                [
                  _c("span", {
                    staticStyle: { width: "11px", height: "22px" },
                    attrs: { "data-icon": "arrow-white" }
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  ref: "arrowRight",
                  staticClass:
                    "btn btn--clear cms-product-slider__arrow cms-product-slider__arrow--right",
                  attrs: {
                    "aria-label": "Kolejny produkt",
                    title: "Kolejny produkt"
                  }
                },
                [
                  _c("span", {
                    staticStyle: { width: "11px", height: "22px" },
                    attrs: { "data-icon": "arrow-white" }
                  })
                ]
              )
            ]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }