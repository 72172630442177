<template>
    <div v-if="hasProductsData" class="cms-product-slider">
        <h2 class="cms-product-slider__title">{{ title }}</h2>
        <div class="cms-product-slider__items products-list">
            <div ref="sliderItems" class="cms-product-slider__wrapper">
                <div
                    v-for="(item, index) in productsData.items"
                    :key="index"
                    :data-gtm-product-id="`${gaListValue}-${item.sku}`"
                    gtm-element="product-item"
                >
                    <div class="cms-product-slider__item product-item">
                        <a
                            :href="item.url"
                            class="cms-product-slider__img product-item__img"
                        >
                            <img
                                :src="item.img"
                                :alt="item.name"
                                width="110"
                                height="110"
                            />
                        </a>
                        <div class="cms-product-slider__content">
                            <a
                                :href="item.url"
                                class="cms-product-slider__header product-item__name"
                            >
                                {{ item.name }}
                            </a>

                            <div class="cms-product-slider__cart">
                                <ProductForm
                                    :form-data="item"
                                    :ga-list-value="gaListValue"
                                    :formkey="formkeyValue"
                                    :widget-type="widgetType"
                                    :widget-title="title"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-show="
                    productsData &&
                    productsData.items &&
                    Object.keys(productsData.items).length > 3
                "
                ref="arrows"
                class="cms-product-slider__switcher"
            >
                <button
                    ref="arrowLeft"
                    aria-label="Poprzedni produkt"
                    title="Poprzedni produkt"
                    class="btn btn--clear cms-product-slider__arrow cms-product-slider__arrow--left"
                >
                    <span
                        data-icon="arrow-white"
                        style="width: 11px; height: 22px"
                    />
                </button>

                <button
                    ref="arrowRight"
                    aria-label="Kolejny produkt"
                    title="Kolejny produkt"
                    class="btn btn--clear cms-product-slider__arrow cms-product-slider__arrow--right"
                >
                    <span
                        data-icon="arrow-white"
                        style="width: 11px; height: 22px"
                    />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import ProductsList from './..//ProductsList'
import handleSliderArrows from '@js/utils/handleSliderArrows.js'
import icons from '@js/app/icons.js'
import ProductForm from './../ProductForm/ProductForm.vue'
export default {
    name: 'ProductsSlider',
    components: {
        ProductForm
    },
    extends: ProductsList,
    inheritAttrs: false,
    props: {
        widgetType: {
            type: String,
            default: 'slider'
        },
        gaListValue: {
            type: String,
            default: 'cms-productsSlider'
        }
    },
    watch: {
        productsData(hasContent) {
            if (hasContent) {
                this.$nextTick(() => {
                    this.initSlider()
                    icons(this.$refs.arrows)
                    window.Aoe_Static.replaceAjaxBlocks()
                })
            }
        }
    },
    methods: {
        initSlider() {
            this.slider = new window.Glider(this.$refs.sliderItems, {
                slidesToShow: 1,
                slidesToScroll: 1,
                rewind: false,
                draggable: false,
                responsive: [
                    {
                        breakpoint: 350,
                        settings: {
                            slidesToShow: 1.5
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 1199,
                        settings: {
                            slidesToShow: 3
                        }
                    }
                ],
                arrows: {
                    prev: this.$refs.arrowLeft,
                    next: this.$refs.arrowRight
                }
            })
            handleSliderArrows(this.slider)
        }
    }
}
</script>
