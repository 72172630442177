<template>
    <div>
        <ProductsList
            v-if="layout === 'productsList'"
            :sku-list="sku"
            :title="title"
            :gaListValue="galist"
            :widget-id="id"
        />

        <SpecialOffer
            v-else-if="layout === 'specialOffer'"
            :sku-list="sku"
            :title="title"
            :gaListValue="galist"
        />

        <ProductsSlider
            v-else-if="layout === 'productsSlider'"
            :sku-list="sku"
            :title="title"
            :gaListValue="galist"
        />
    </div>
</template>

<script>
import SpecialOffer from './components/SpecialOffer'
import ProductsSlider from './components/ProductsSlider'
import ProductsList from './components/ProductsList'

export default {
    scopeName: 'widgets',
    components: {
        SpecialOffer,
        ProductsList,
        ProductsSlider
    },
    data() {
        return {
            sku: '',
            title: '',
            id: '',
            layout: '',
            productsData: {}
        }
    },
    created() {
        this.sku = this.$root.$el.parentElement
            .getAttribute('data-sku')
            .replace(/[\n\s]+/g, '')
            .split(',')
        this.layout = this.$root.$el.parentElement.getAttribute('data-layout')
        this.title = this.$root.$el.parentElement.getAttribute('data-header')
        this.galist = this.$root.$el.parentElement.getAttribute('data-list')
        this.id = this.$root.$el.parentElement.getAttribute('data-id')
    }
}
</script>
