<template>
    <div v-if="hasProductsData" class="content-product-list">
        <h3
            ref="header"
            class="content-product-list__title"
            :data-widget="widgetId"
            @click="addTabClass"
        >
            {{ title }}
        </h3>
        <ul class="content-product-list__items">
            <li
                v-for="(item, index) in productsData.items"
                :key="index"
                :data-gtm-product-id="`${gaListValue}-${item.sku}`"
                gtm-element="product-item"
            >
                <a :href="item.url" class="content-product-list__link">
                    <div class="content-product-list__img">
                        <img
                            :src="item.img"
                            :alt="item.name"
                            width="100"
                            height="100"
                        />
                    </div>
                    <div class="content-product-list__data">
                        <p class="content-product-list__name">
                            {{ item.name }}
                        </p>
                        <p class="content-product-list__price">
                            {{ formatPrice(item.price) }}
                        </p>
                    </div>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
import axios from 'axios'
import { formatPrice } from '@js/utils/utils'
export default {
    name: 'ProductsList',
    inheritAttrs: false,
    props: {
        skuList: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: ''
        },
        formkey: {
            type: String,
            default: ''
        },
        widgetId: {
            type: String,
            default: ''
        },
        galist: {
            type: String,
            default: ''
        },
        gaListValue: {
            type: String,
            default: 'cms-productsList'
        }
    },
    data() {
        return {
            productsData: {},
            formkeyValue: ''
        }
    },
    computed: {
        hasProductsData() {
            return (
                this.productsData &&
                this.productsData.items &&
                Object.keys(this.productsData.items).length
            )
        },
        listName() {
            return 'cms-productsList'
        }
    },
    watch: {
        productsData(hasContent) {
            if (hasContent) {
                this.$nextTick(() => {
                    if (window?.GoogleAnalyticsUniversal) {
                        const products = this.productsData.items
                        Object.entries(products).forEach(
                            ([key, product], index) => {
                                const name = this.gaListValue + '-' + key
                                const item = {
                                    brand: product.brand,
                                    category: product.category,
                                    id: product.sku,
                                    list: this.gaListValue + '-' + this.title,
                                    listPosition: index,
                                    name: product.name,
                                    position: index,
                                    variant: product.variant
                                }
                                window.staticImpressions[name] = item
                            }
                        )
                    }
                })
            }
        }
    },
    created() {
        axios
            .get('/cmsintegrator/cms/getProductsBySku', {
                params: {
                    sku: this.skuList,
                    accept: 'jpg'
                }
            })
            .then((response) => {
                var items = []
                this.skuList.forEach((sku) =>
                    items.push(response.data.items[sku])
                )
                response.data.items = items
                this.productsData = response.data
                this.formkeyValue = this.productsData.formKey
            })
            .catch((error) => {
                console.error(error)
            })
    },
    methods: {
        formatPrice(price) {
            return formatPrice(price)
        },
        addTabClass() {
            const allContainers = [
                ...document.querySelectorAll('.content-columns__col')
            ]
            allContainers.forEach((container) =>
                container.classList.remove('is-active')
            )

            const headerText = this.$refs.header.dataset.widget
            const container = this.$refs.header.closest(
                `[data-id="${headerText}"]`
            )
            if (container) {
                container.parentElement.parentElement.classList.add('is-active')
            }
        }
    }
}
</script>
